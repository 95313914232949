module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MolnX","short_name":"MolnX","start_url":"/","display":"standalone","theme_color":"#394b59","background_color":"#f0f4f7","icons":[{"src":"/images/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"/images/favicon-128.png","sizes":"128x128","type":"image/png"},{"src":"/images/favicon-64.png","sizes":"64x64","type":"image/png"},{"src":"/images/favicon-32.png","sizes":"32x32","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"^\\/docs"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
